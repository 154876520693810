/* =========== onb-initial-block page ========== */

.onb-mobile-block {
  .onb-form-group {
    width: 100%;
    max-width: 160px;
  }
}

.onb-dual-citizen {
  margin-bottom: 10px;
  .radio-label {
    margin: 0.625rem 1.5625rem 0.625rem 0;
  }
}
.onb-btn-footer {
  padding: 0 30px;
}

/* =========== onb-contact page ========== */
.onb-form-block {
  width: 100%;
  max-width: none;

  .onb-btn-footer {
    .btn-red {
      width: 100%;
    }
  }
}

/* =========== onb-summary-details page ========== */

.onb-summary-details {
  .onb-col-block {
    flex-direction: column;
  }
  .onb-row {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .onb-col-lable {
    @include fontSize(13px);
    @include fontFamily("liberty-Nus Medium");
    color: $grey;
    width: 50%;
  }
  .onb-col {
    @include fontSize(13px);
    @include fontFamily("liberty-Nus Bold");
    color: $black;
    width: 50%;
    text-align: right;
  }

  .onb-left-col {
    width: 100%;
    margin-right: 0;
  }
  .onb-right-col {
    width: 100%;
  }

  .cont-text {
    background-color: #fff;
    margin: -95px -23px;
    padding: 95px 20px 0;

    .divider {
      display: none;
    }
  }

  .onb-info-blcok {
    margin-top: 110px;
  }
}

/* =========== Notification Container page ========== */
.onb-notification-block {
  width: 100%;
  max-width: 755px;

  .onb-card-block {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;

    .icn-circle-white {
      width: 58px;
      height: 58px;
      margin-bottom: 8px;
      svg {
        height: 26px;
      }
    }
    .onb-icn-block {
      display: block !important;
    }
  }

  .icn-tick-circle {
    background-color: #279a23;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 35px;
    svg {
      width: 28px;
      height: 20px;
      color: $white;
    }
  }

  .icn-close-circle {
    background-color: $red;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 35px;
    svg {
      // width: 15px;
      height: 30px;
      color: $white;
    }
  }

  .icn-info {
    width: 56px;
    height: 56px;
    margin: 0 auto 35px;
  }

  h3 {
    @include fontSize(16px);
    @include fontFamily("liberty-MT Medium");
    font-weight: normal;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.6;
  }

  p {
    @include fontSize(14px);
    color: #747474;
  }

  .onb-btn {
    &.btn-red {
      min-width: 290px;
    }
  }
}
