// new variables
$white: #fff;
$black: #000;
$basic-grey: #242424;
$basic-black: #242424;
$grey: #89898e;
$red: #db0011;

.is-red {
  color: $red;
}

.is-grey {
  color: $grey;
}
