html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  @include fontFamily("Liberty-Nus");
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: $basic-grey;
  background-color: #f9f9f9;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

svg {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  outline: 0;
  color: $basic-grey;
  text-decoration: none;
  background-color: transparent; /* Remove the gray background on active links in IE 10. */
  -webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+. */
}
a:hover {
  color: #171f27;
  text-decoration: none;
}

img {
  max-width: 100%;
}

// remove dashed line in firefox
button::-moz-focus-inner {
  border: 0;
}
// remove dashed line in firefox
input::-moz-focus-inner {
  outline: 0;
}

//   FONTS

@font-face {
  font-family: "Liberty-Nus";
  src: url("../fonts/Liberty-Nus-Regular.eot");
  src: url("../fonts/Liberty-Nus-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Liberty-Nus-Regular.woff2") format("woff2"),
    url("../fonts/Liberty-Nus-Regular.woff") format("woff"),
    url("../fonts/Liberty-Nus-Regular.ttf") format("truetype"),
    url("../fonts/Liberty-Nus-Regular.svg#Liberty-Nus-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Liberty-Nus Medium";
  src: url("../fonts/Liberty-Nus-Medium.eot");
  src: url("../fonts/Liberty-Nus-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Liberty-Nus-Medium.woff2") format("woff2"),
    url("../fonts/Liberty-Nus-Medium.woff") format("woff"),
    url("../fonts/Liberty-Nus-Medium.ttf") format("truetype"),
    url("../fonts/Liberty-Nus-Medium.svg#Liberty-Nus-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Liberty-Nus Bold";
  src: url("../fonts/Liberty-Nus-Bold.eot");
  src: url("../fonts/Liberty-Nus-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Liberty-Nus-Bold.woff2") format("woff2"),
    url("../fonts/Liberty-Nus-Bold.woff") format("woff"),
    url("../fonts/Liberty-Nus-Bold.ttf") format("truetype"),
    url("../fonts/Liberty-Nus-Bold.svg#Liberty-Nus-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Liberty-MT";
  src: url("../fonts/Liberty-MT-Regular.eot");
  src: url("../fonts/Liberty-MT-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Liberty-MT-Regular.woff2") format("woff2"),
    url("../fonts/Liberty-MT-Regular.woff") format("woff"),
    url("../fonts/Liberty-MT-Regular.ttf") format("truetype"),
    url("../fonts/Liberty-MT-Regular.svg#Liberty-MT-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Liberty-MT Medium";
  src: url("../fonts/Liberty-MT-Medium.eot");
  src: url("../fonts/Liberty-MT-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Liberty-MT-Medium.woff2") format("woff2"),
    url("../fonts/Liberty-MT-Medium.woff") format("woff"),
    url("../fonts/Liberty-MT-Medium.ttf") format("truetype"),
    url("../fonts/Liberty-MT-Medium.svg#Liberty-MT-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Liberty-MT Bold";
  src: url("../fonts/Liberty-MT-Bold.eot");
  src: url("../fonts/Liberty-MT-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Liberty-MT-Bold.woff2") format("woff2"),
    url("../fonts/Liberty-MT-Bold.woff") format("woff"),
    url("../fonts/Liberty-MT-Bold.ttf") format("truetype"),
    url("../fonts/Liberty-MT-Bold.svg#Liberty-MT-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

// dot font
@font-face {
  font-family: "dotsfont";
  src: url("../fonts/dots/dotsfont.eot");
  src: url("../fonts/dots/dotsfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/dots/dotsfont.svg#font") format("svg"),
    url("../fonts/dots/dotsfont.woff") format("woff"),
    url("../fonts/dots/dotsfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
