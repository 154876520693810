// convert px to rem
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  // font-size: $size;
  font-size: calculateRem($size);
}

@mixin iconSize($size) {
  // width: $size;
  width: calculateRem($size);

  // height: $size;
  height: calculateRem($size);
}


@mixin fontFamily($font) {
  font-family: $font, Arial, sans-serif;
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}