.onb-container {
  width: 100%;
  margin: 0 auto;
}

.onb-main-container {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  //  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.onb-wrap-container {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  // flex-wrap: wrap;
  align-items: stretch;
}

/*========== onb-logo-block ==========*/
.onb-logo-block {
  padding: 0 53px;
  margin-top: 98px;

  // position: fixed;
  // top: 98px;
  // z-index: 1030;

  .arrow-left {
    width: 22px;
    height: 17px;
  }
  img {
    width: 180px;
  }
}
/*========== onb-left-block ==========*/

.onb-left-block {
  width: 1030px;
  min-height: 100vh;
  display: block;
  padding: 0 0;
  display: flex;
  justify-content: start;
  flex-direction: column;
}

/*========== onb-right-block ==========*/

.onb-right-block {
  width: calc(100% - 1030px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;

  .onb-right-body {
    height: 100%;
    position: relative;
  }
}

/*========== onb-lang-block ==========*/

.onb-lang-block {
  @include fontSize(13px);
  @include fontFamily("liberty-Nus Medium");
  padding: 55px 70px;

  span {
    display: block;
  }
}

/*========== onb-chat-block ==========*/
.onb-chat-block {
  position: absolute;
  bottom: 30px;
  right: 30px;
  .onb-chat-body {
    background-color: $black;
    padding: 15px 18px;
    border-radius: 0 30px 30px 0;

    span {
      @include fontSize(12px);
      @include fontFamily("liberty-MT Medium");
      color: $white;
    }
  }
}

/*========== scrollbar ==========*/
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
/*========== onb-content-block ==========*/
.onb-content-block {
  padding: 0 25px;
  margin-top: 95px;
  position: relative;
  h1 {
    @include fontSize(23px);
    @include fontFamily("liberty-MT Medium");
    color: $black;
  }

  p {
    @include fontSize(18px);
    @include fontFamily("liberty-Nus Medium");
    color: $black;
  }

  .onb-infotext-block {
    h5 {
      @include fontSize(16px);
    }
    p {
      @include fontSize(14px);
      @include fontFamily("liberty-Nus Medium");
    }

    .onb-list-text {
      div {
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: $basic-grey;
          border-radius: 50%;
          display: inline-block;
          margin-top: 9px;
        }
        p {
          padding-left: 6px;
          flex: 1 1;
        }
      }
      &.red-border {
        div {
          &::before {
            content: "";
            width: 5px;
            height: 5px;
            background-color: $white;
            border: 1px solid $red;
            border-radius: 50%;
            display: inline-block;
            margin-top: 9px;
          }
          p {
            padding-left: 6px;
            flex: 1 1;
            @include fontFamily("liberty-Nus Bold");
          }
        }
      }
    }
  }

  .icn-circle-white {
    background-color: $white;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 38px;
    }

    &.icn-intmob-bank {
      svg {
        height: auto !important;
      }
    }

    &.icn-usre {
      svg {
        height: auto;
      }
    }
    &.icn-enter {
      svg {
        height: auto;
      }
    }
  }

  .onb-icn-block {
    display: none;
  }
}

/*========== onb-footer-block ==========*/

.onb-footer-block {
  border-top: 1px solid #dddddd;
  width: 100%;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include fontSize(15px);
  @include fontFamily("liberty-Nus Medium");
}

/* =========== dots fonts ========== */
.dot-input {
  font-family: "dotsfont" !important;
  font-size: 10px !important;
}

/* =========== button modules ========== */

.onb-btn {
  display: inline-flex;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0 1.56rem;
  height: 45px;
  min-width: 4.75rem;
  // text-align: center;
  font-size: 15px;
  @include fontFamily("Liberty-MT Medium");
  border-radius: 4px;
  transition: 0.3s;

  & + .onb-btn {
    margin-left: 15px;
  }

  &:disabled {
    background: #e7e7e9 !important;
    color: #fff !important;
    cursor: default;
    border: none !important;
  }

  &.btn-red {
    background: $red;
    height: 60px;
    margin-left: 0;
    color: $white;
    width: 100%;

    &:hover {
      background: #cc0111;
    }
  }
  &.btn-grey {
    background: #e9ebed;
    border: 1px solid #dedfdf;
    height: 60px;
    margin-left: 0.625rem;
    color: #242424;
    &:hover {
      background: #dddddd;
      border: 1px solid #dddddd;
    }
  }
  &.btn-link {
    @include fontSize(14px);
    @include fontFamily("Liberty-Nus Medium");
    padding: 0;
    min-width: auto;
    height: auto !important;
    background: transparent;
    display: inline-flex;
    align-items: center;
    color: #1d262f;
    .icn {
      height: 7px;
    }
  }
  &.btn-link {
    display: none;
  }
}

/* =========== onb-form-group ========== */

/* =========== Checkbox ========== */
.checkbox-wrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  @include fontSize(14px);
  @include fontFamily("Liberty-Nus Medium");

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ .checkmark {
      background-color: #171827;
      border-color: #171827;

      svg {
        opacity: 1;
      }
    }
  }

  &.dark-red input:checked ~ .checkmark {
    background-color: $red;
    border-color: $red;
  }

  &.black input:checked ~ .checkmark {
    background-color: #171827;
    border-color: #171827;
  }

  span {
    order: 2;
  }

  &.dark-red .checkmark {
    background: transparent;
  }

  .checkmark {
    position: relative;
    margin: 0 10px 0 0;
    order: 1;
    height: 16px;
    width: 16px;
    background-color: #fff;
    color: #fff;
    border: 1px solid #d2d2d7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 50%;
      height: 50%;
      opacity: 0;
    }
  }
}
/* =========== radio buttons - checked ========== */

.radio-label {
  display: flex;
  align-items: center;
  margin: 0.9375rem 0;
  cursor: pointer;
  &.service-radio-label {
    margin: 8px 0;
  }
  .radio-button {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  span {
    order: 2;
  }
}
.radio-box {
  display: block;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #d2d2d7;
  margin: 0 10px 0 0;
  order: 1;
}

.radio-button:checked {
  & ~ .radio-box {
    border: 4px solid #000;
    background: #fff;
  }

  & ~ span {
    @include fontFamily("Liberty-Nus Bold");
    @include fontSize(14px);
  }
}

/* =========== onb-form-control ========== */
.label-red {
  @include fontFamily("Liberty-Nus Medium");
  @include fontSize(14px);
  color: $red;
}

.onb-hint {
  @include fontFamily("Liberty-Nus Medium");
  @include fontSize(12px);
  color: #89898e;
}

.onb-info-blcok {
  background-color: $white;
  border: 2px dashed #d2d2d7;
  padding: 10px 15px;
  border-radius: 4px;

  .icn {
    width: 30px;
    height: 30px;
  }
}
.onb-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d2d7;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #212529;
    background-color: #fff;
    // border-color: #86b7fe;
    outline: 0;
    // box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  &::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
  }

  &::placeholder {
    color: #6c757d;
    opacity: 1;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media (prefers-reduced-motion: reduce) {
  .onb-form-control {
    transition: none;
  }
}
.onb-mobile-block {
  .onb-form-floating {
    > label {
      padding: 0.3125rem 0.75rem;
      font-size: 11px;
    }
  }
}
.onb-form-floating {
  position: relative;
  margin-bottom: 25px;
  > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    @include fontFamily("Liberty-Nus Medium");
    color: $grey;
    padding: 0.875rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    font-size: 11px;
  }
  > .onb-form-control {
    height: calc(3.5rem + 2px);
    padding: 1rem 0.75rem;
    &:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      & ~ label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(-0.15rem);
      }
    }

    &:focus {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      outline: none;
      & ~ label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(-0.15rem);
      }
    }
    &::-moz-placeholder {
      color: transparent;
    }
    &::placeholder {
      color: transparent;
    }
  }

  > .onb-control-textarea {
    height: auto;
    padding: 1rem 0.75rem;
    &:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      & ~ label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(-0.15rem);
      }
    }

    &:focus {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      outline: none;
      & ~ label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(-0.15rem);
      }
    }
    &::-moz-placeholder {
      color: transparent;
    }
    &::placeholder {
      color: transparent;
    }
  }

  > .onb-form-select {
    height: calc(3.5rem + 2px);
    padding: 1rem 0.75rem;
    &:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      & ~ label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(-0.15rem);
      }
    }

    &:focus {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      & ~ label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(-0.15rem);
      }
    }
    &::-moz-placeholder {
      color: transparent;
    }
    &::placeholder {
      color: transparent;
    }
  }

  &.is-error {
    .onb-form-control:not(:focus) {
      border-color: $red;
      background-color: #fff1f4;
    }
    label {
      color: $red;
    }
  }

  .form-message-wrapper {
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -0.01em;
    @include fontSize(12px);
    @include fontFamily("Liberty-Nus");
    color: $red;
    position: relative;
    margin-top: 5px;
    // margin-bottom: 20px;
  }

  &.disabled {
    opacity: 0.6;
  }
}

@media (prefers-reduced-motion: reduce) {
  .onb-form-floating > label {
    transition: none;
  }
}

label {
  display: inline-block;
}

.onb-form-select {
  display: block;
  width: 100%;
  padding: 1rem 2.25rem 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #d2d2d7;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}
.onb-input-out {
  position: relative;
}
.icn-notif {
  position: absolute;
  right: 3%;
  top: 38%;

  svg {
    color: #000;
  }
}

/* =========== onb-select ========== */

.onb-select {
  .select__indicator {
    width: 17px;
    padding: 0;
    color: #717073;
    margin-right: 11px;
  }
  .select__control {
    height: 58px;
  }

  .select__indicator-separator {
    display: none;
  }
  .select__single-value {
    color: #000;
    font-size: 15px;
    padding-top: 15px;
  }
  .select__value-container {
    padding: 8px 0.75rem;
    height: calc(3.5rem + 2px);
  }

  .select__control {
    border-color: #d2d2d7 !important;
  }

  .select__menu {
    max-height: 300px;
    margin: 3px 0 0 0;
    display: block !important;
    .select__option {
      @include fontSize(14px);
      @include fontFamily("Liberty-Nus Medium");
      color: #1d1d1f;
      padding: 10px 16px;

      &.select__option--is-focused {
        background-color: #f4f4f4 !important;
      }
      &.select__option--is-selected {
        background-color: #f4f4f4 !important;
      }
    }
  }

  .select__control--is-focused {
    border-color: none;
    box-shadow: none;
  }
  .placeholder {
    span {
      color: #6c757d;
      display: block;
      opacity: 0.65;
      @include fontFamily("Liberty-Nus Medium");
      @include fontSize(12px);
    }
  }

  &.service-select {
    .select__single-value {
      padding-top: 0;
    }
  }

  &.select-phone {
    .select__single-value {
      padding-top: 0;
    }
  }
  &.select-paddtop {
    .select__single-value {
      padding-top: 0;
    }
  }
}

/* =========== onb-initial-block ========== */

.onb-initial-block {
  width: 100%;
  max-width: 500px;
  margin: auto;
  .onb-register {
    h1 {
      @include fontSize(18px);
    }
  }
  p {
    color: $grey;
  }
}

/* =========== onb-tooltip ========== */
.onb-tooltip {
  width: 100%;
  max-width: 345px;
  text-align: left;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-size: 12px !important;

  &.type-darker {
    background: #e6e6e6;
    color: $black;
    border-radius: 4px;
    @include fontFamily("Liberty-Nus Medium");
    @include fontSize(12px);
    font-style: normal;
    max-width: 120px;
    &.place-top:after {
      border-top-color: #e6e6e6 !important;
    }

    &.place-bottom:after {
      border-bottom-color: #e6e6e6 !important;
    }

    &.place-right:after {
      border-right-color: #e6e6e6 !important;
    }

    &.place-left:after {
      border-left-color: #e6e6e6 !important;
    }
  }
}
.tooltip-parent {
  display: inline-block;
  max-width: 100%;
  height: 100%;
}

/* =========== onb-type-block ========== */
.onb-type-block {
  width: 100%;
  max-width: 685px;
  .onb-type-box {
    background-color: #e9ebed;
    border-radius: 4px;
    padding: 25px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;
    cursor: pointer;
    h3 {
      @include fontSize(15px);
      @include fontFamily("Liberty-Nus Bold");
    }
    p {
      @include fontSize(13px);
      @include fontFamily("Liberty-Nus Medium");
    }
    .onb-type-info {
      flex: 1 1;
    }

    &:hover {
      background-color: $red;

      h3 {
        color: $white;
      }
      p {
        color: $white;
      }
    }
  }
}

/* =========== onb-modal-head ========== */
.onb-popup {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .onb-popup-head {
    padding: 23px 23px 23px 40px;
    color: $black;
    @include fontFamily("Liberty-MT Medium");
    @include fontSize(17px);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icn-close {
    color: #000;
    height: 0.75rem;
    width: 0.75rem;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: $red;
    }
  }

  .onb-popup-body {
    width: 100%;
    max-width: 600px;
    min-width: 350px;
    background: #f7f8f9;
    border-radius: 4px;
    overflow: hidden;
    outline: none;

    .onb-popup-head {
      min-height: 65px;
      height: 65px;
      border-bottom: 1px solid #dedee4;
      background: #f4f4f4;
    }

    .icn-info {
      width: 43px;
      height: 43px;
    }
    h1 {
      font-weight: normal;
    }
  }

  .onb-popup-row {
    padding: 40px 31px;
  }

  .onb-popup-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 35px 40px;

    .onb-btn {
      height: 50px;
    }
  }
}

/* =========== responsive mobile header ========== */
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.onb-mobile-header {
  background-color: #fff;
  width: 100%;
  height: 62px;
  box-shadow: 0 0 16px rgba(95, 95, 84, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .onb-logo-block {
    padding: 0 30px 0 0;
    margin-top: 5px;
    position: unset;
    img {
      height: 38px;
    }
  }
  .onb-lang-block {
    padding: 0 30px;
  }
}

.onb-chat-mobile {
  position: absolute;
  right: 10%;
  top: 15%;
  //bottom: 15%;
}

/* =========== chat ========== */
.chat-open {
  position: fixed;
  bottom: -4px;
  right: 0;
  overflow: hidden;
  width: 406px;
}
/* =========== contact container page ========== */

.onb-contact-container {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row-reverse;

  .onb-left-banner {
    width: 288px;
    background-color: $white;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05),
      0 2px 10px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05),
      0 2px 10px 0 rgba(0, 0, 0, 0.05);

    .onb-logo-block {
      padding: 90px 0 0 0;

      img {
        width: 180px;
      }
    }
    .onb-contact-links {
      @include fontFamily("Liberty-Nus Medium");
      @include fontSize(13px);
      color: $grey;
      text-align: left;
      margin: auto 0 25px 0;

      a {
        display: block;
      }
    }
    .onb-contact-foot {
      width: 100%;
      @include fontFamily("Liberty-Nus Medium");
      @include fontSize(15px);
      text-align: center;
      border-top: 1px solid #ddd;
      padding: 25px 45px;
    }
  }
  .onb-contact-form {
    width: 100%;
    min-height: 100vh;
    margin-left: 0;
    display: block;
    padding: 95px 25px 0;
    position: relative;
    h1 {
      @include fontSize(18px);
      @include fontFamily("liberty-MT Medium");
      color: $black;
    }
    p {
      @include fontSize(16px);
      @include fontFamily("liberty-Nus Medium");
      color: $grey;
    }

    .onb-details-block {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      .onb-chat-block {
        position: static;
        margin: 0 30px 26px 0;
      }
      // .onb-lang-block {
      //   padding: 55px 0 0 0;
      // }
    }
  }
}

/* =========== progress-container ========== */

.progress-container {
  width: 100%;
  margin: 0 auto 50px auto;

  .progress-steps {
    counter-reset: step;
    display: flex;
    margin-left: -76px;

    a {
      list-style-type: none;
      width: 25%;
      @include fontFamily("Liberty-Mt Medium");
      @include fontSize(16px);
      position: relative;
      text-align: center;
      color: #89898e;

      &:before {
        width: 36px;
        height: 36px;
        content: counter(step);
        counter-increment: step;
        line-height: 36px;
        display: block;
        text-align: center;
        margin: 0 auto 0.7em auto;
        border-radius: 50%;
        background-color: #d2d2d7;
        color: #89898e;
      }
      &:after {
        width: 100%;
        height: 2px;
        content: "";
        position: absolute;
        background-color: #d2d2d7;
        top: 1.3em;
        left: -50%;
        z-index: -1;
      }
      &:first-child:after {
        content: none;
      }
      &.active {
        color: #1d1d1f;
        &:before {
          color: $white;
          background-color: #1d1d1f;
        }
      }

      &.checked {
        color: #1d1d1f;
        &:before {
          content: "\2714";
          color: $white;
          background-color: #19b05d;
        }
      }
    }
  }
}

/* =========== loader ========== */

.loader {
  width: 100px;
  height: 100px;
  margin: 0;
  background: transparent;
  border-top: 4px solid $red;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;

  &.loader-xxs {
    width: 20px;
    height: 20px;
    border-width: 2px;
  }

  &.loader-xs {
    width: 30px;
    height: 30px;
    border-width: 2px;
  }
  &.loader-xsm {
    width: 40px;
    height: 40px;
    border-width: 3px;
  }

  &.loader-sm {
    width: 60px;
    height: 60px;
    border-width: 3px;
  }
}
.loader-out {
  &.full-size {
    position: absolute;
    top: 0;
    z-index: 3;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
  }

  &.fixed {
    position: fixed;
    top: 116px;
  }

  &.decimal-loader {
    height: 50px !important;
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.divider {
  background-color: #e5e5e5;
  width: 100%;
  height: 1px;
}

.icn {
  &.icn-dark-circle {
    background: #e3e3e3;
    border-radius: 50%;
    color: #242424;
    width: 21px;
    height: 21px;
    padding-top: 6px;
    cursor: pointer;
    padding-left: 4px;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    svg {
      height: 9px;
    }

    &:hover {
      background: $black;

      svg {
        color: $white;
      }
    }
  }
}

/* =========== onb-resend-code ========== */
.onb-resend-code {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 20px 0 35px 0;
  flex-direction: column;

  .onb-form-floating {
    margin-bottom: 0;
    width: 100%;

    input {
      // max-width: 175px;
      max-width: 100%;
    }
  }
  .onb-btn-code {
    @include fontSize(14px);
    @include fontFamily("Liberty-Nus Medium");
    padding: 0;
    min-width: auto;
    height: auto !important;
    background: transparent;
    display: inline-flex;
    align-items: center;
    color: #1d262f;
    order: 2;
    margin: 20px 0 0 0;
  }
  .icn-refresh {
    width: 20px;
    height: 20px !important;
  }
}

/* =========== onb-accordion ========== */

.lb-accordion-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  margin-bottom: 8px;

  .lb-accordion-header {
    margin-bottom: 1px;
    background-color: #fafafa;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    height: 42px;
    margin: 0 -20px 10px;

    .lb-btn-accord {
      background: transparent;
      height: 42px !important;
      padding: 0 0.6875rem !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .icn.arrow-down {
      width: 12px;
      height: 12px;
    }
    .icn.arrow-up {
      width: 12px;
      height: 12px;
    }
  }

  .lb-accordion-body {
    flex: 1 1 auto;
  }
}

input:disabled {
  background-color: #89898e29 !important;
  border-color: #89898e6e;
}
