/*========== max-width ==========*/

@media (max-width: 992px) {
  .progress-container {
    width: 50%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .onb-left-banner {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .progress-container {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .progress-container {
    display: none !important;
  }
  .onb-left-banner {
    display: none !important;
  }
}
@media (max-width: 576px) {
  .onb-details-block {
    display: none !important;
  }
}
/*========== min-width ==========*/

@media (min-width: 576px) {
  .d-sm-block {
    display: block !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .onb-content-block {
    padding: 0 25px;
    margin-top: 95px;
    h1 {
      @include fontSize(23px);
    }
    .onb-icn-block {
      display: none;
    }
  }
  .onb-btn {
    &.btn-red {
      width: 35%;
      margin-left: 0.625rem;
    }

    &.btn-link {
      display: block;
    }
  }

  .onb-btn-footer {
    padding: 0;
  }
  .onb-initial-block {
    .onb-register {
      h1 {
        @include fontSize(28px);
      }
    }
  }
  .progress-container {
    display: none !important;
  }
  .onb-details-block {
    display: none !important;
  }
  .onb-left-banner {
    display: none !important;
  }
  .onb-contact-container {
    .onb-contact-form {
      width: 100%;
      padding: 95px 25px;
      margin-left: 0;

      h1 {
        @include fontSize(18px);
      }
      p {
        @include fontSize(14px);
      }
    }
  }
  .onb-form-block {
    max-width: none;
    .onb-btn-footer {
      .btn-red {
        width: 60%;
      }
    }
  }
  .onb-col-block {
    flex-direction: column !important;
    .onb-left-col {
      width: 100%;
      margin-right: 0;
    }
    .onb-right-col {
      width: 100%;
    }
  }
  .onb-resend-code {
    align-items: center;
    flex-direction: unset !important;

    .onb-btn-code {
      order: inherit;
      margin: 0 20px 0 0;
    }
    .onb-form-floating {
      margin-bottom: 0;
      width: auto;

      input {
        max-width: 175px;
      }
    }
  }
  .onb-form-floating {
    > label {
      padding: 1rem 0.75rem;
      font-size: 14px;
    }
  }
  .onb-mobile-block {
    .onb-form-floating {
      > label {
        padding: 1rem 0.75rem;
        font-size: 14px;
      }
    }
  }
  .onb-notification-block {
    h3 {
      @include fontSize(20px);
    }

    p {
      @include fontSize(18px);
    }

    .onb-card-block {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 30px;

      .icn-circle-white {
        width: 58px;
        height: 58px;
        margin-bottom: 8px;
        svg {
          height: 26px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
  .d-md-none {
    display: none !important;
  }
  .onb-right-body {
    display: none !important;
  }

  .onb-content-block {
    padding: 0 40px;
    margin-top: 0;
    h1 {
      @include fontSize(18px);
    }
    p {
      @include fontSize(16px);
    }
    .onb-icn-block {
      display: block;
    }
  }

  .progress-container {
    display: none !important;
  }
  .onb-details-block {
    display: none !important;
  }
  .onb-left-banner {
    display: none !important;
  }

  .onb-contact-container {
    .onb-contact-form {
      width: 100%;
      padding: 95px 50px;
      margin-left: 0;
      h1 {
        @include fontSize(30px);
      }
      p {
        @include fontSize(14px);
      }
    }
  }
  .onb-form-block {
    max-width: none;
    .onb-btn-footer {
      .btn-red {
        width: 60%;
      }
    }
  }
  .onb-col-block {
    flex-direction: column !important;
    .onb-left-col {
      width: 100%;
      margin-right: 0;
    }
    .onb-right-col {
      width: 100%;
    }
  }
  .onb-resend-code {
    align-items: center;
    flex-direction: unset !important;

    .onb-btn-code {
      order: inherit;
      margin: 0 20px 0 0;
    }
    .onb-form-floating {
      margin-bottom: 0;
      width: auto;

      input {
        max-width: 175px;
      }
    }
  }
  .onb-notification-block {
    h3 {
      @include fontSize(20px);
    }

    p {
      @include fontSize(18px);
    }

    .onb-card-block {
      flex-direction: unset;
      margin-bottom: 30px;

      .icn-circle-white {
        width: 84px;
        height: 84px;
        margin-bottom: 0;
        svg {
          height: 38px;
        }
      }
    }
  }
  .onb-type-block {
    .onb-type-box {
      flex-direction: unset;
    }
  }
  .onb-summary-details {
    .cont-text {
      background-color: transparent;
      margin: 0;
      padding: 0;
      .divider {
        display: block;
      }
    }
    .onb-info-blcok {
      margin-top: 0;
    }
  }
  .lb-accordion-block {
    .lb-accordion-header {
      margin-bottom: 1px;
      background-color: transparent;
      border-top: none;
      border-bottom: none;
      height: 42px;
      margin: 0 -20px 10px;

      .icn-arrow {
        display: none;
      }
    }
  }
}

@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
  .d-lg-none {
    display: none !important;
  }

  .onb-right-body {
    display: none !important;
  }

  .onb-content-block {
    padding: 0 40px;
    margin-top: 0;
    h1 {
      @include fontSize(28px);
    }
    .onb-icn-block {
      display: block;
    }
  }
  .progress-container {
    display: none !important;
  }
  .onb-details-block {
    display: none !important;
  }
  .onb-left-banner {
    display: none !important;
  }

  .onb-contact-container {
    .onb-contact-form {
      width: calc(100% - 288px);
      padding: 95px 50px;
      margin-left: 0;
      h1 {
        @include fontSize(30px);
      }
      p {
        @include fontSize(14px);
      }
    }
  }
  .onb-form-block {
    max-width: 560px;
    .onb-btn-footer {
      .btn-red {
        width: 60%;
      }
    }
  }
  .onb-col-block {
    flex-direction: column !important;
    .onb-left-col {
      width: 100%;
      margin-right: 0;
    }
    .onb-right-col {
      width: 100%;
    }
  }
  .onb-col {
    text-align: right !important;
  }

  .onb-resend-code {
    align-items: center;
    flex-direction: unset !important;

    .onb-btn-code {
      order: inherit;
      margin: 0 20px 0 0;
    }
    .onb-form-floating {
      margin-bottom: 0;
      width: auto;

      input {
        max-width: 175px;
      }
    }
  }
  .onb-notification-block {
    h3 {
      @include fontSize(20px);
    }

    p {
      @include fontSize(18px);
    }

    .onb-card-block {
      flex-direction: unset;
      margin-bottom: 30px;

      .icn-circle-white {
        width: 84px;
        height: 84px;
        margin-bottom: 0;
        svg {
          height: 38px;
        }
      }
    }
  }
  .onb-summary-details {
    .cont-text {
      background-color: transparent;
      margin: 0;
      padding: 0;
      .divider {
        display: block;
      }
    }
    .onb-info-blcok {
      margin-top: 0;
    }
  }
  .lb-accordion-block {
    .lb-accordion-header {
      margin-bottom: 1px;
      background-color: transparent;
      border-top: none;
      border-bottom: none;
      height: 42px;
      margin: 0 -20px 10px;

      .icn-arrow {
        display: none;
      }
    }
  }
}

@media (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .onb-right-body {
    display: block !important;

    .onb-chat-body {
      padding: 15px 12px;
    }
    .onb-chat-block {
      right: 10px;
    }
  }
  .onb-content-block {
    padding: 0 98px;
    margin-top: 0;
    h1 {
      @include fontSize(28px);
    }
    .onb-icn-block {
      display: block;
    }
  }

  .progress-container {
    display: block !important;
  }
  .onb-details-block {
    display: block !important;
  }
  .onb-left-banner {
    display: block !important;
  }

  .onb-contact-container {
    .onb-contact-form {
      padding: 70px 140px;
    }
  }
  .onb-contact-container {
    .onb-contact-form {
      width: calc(100% - 288px);
      padding: 70px 140px;
      margin-left: 288px;

      h1 {
        @include fontSize(30px);
      }
      p {
        @include fontSize(14px);
      }
    }
  }
  .onb-form-block {
    max-width: 560px;
    .onb-btn-footer {
      .btn-red {
        width: 60%;
      }
    }
  }
  .onb-col-block {
    flex-direction: row !important;
    .onb-left-col {
      width: 50%;
      margin-right: 100px;
    }
    .onb-right-col {
      width: 50%;
    }
  }
  .onb-col {
    text-align: left !important;
  }
  .onb-resend-code {
    align-items: center;
    flex-direction: unset !important;

    .onb-btn-code {
      order: inherit;
      margin: 0 20px 0 0;
    }
    .onb-form-floating {
      margin-bottom: 0;
      width: auto;

      input {
        max-width: 175px;
      }
    }
  }

  .onb-notification-block {
    h3 {
      @include fontSize(20px);
    }

    p {
      @include fontSize(18px);
    }

    .onb-card-block {
      flex-direction: unset;
      margin-bottom: 30px;

      .icn-circle-white {
        width: 84px;
        height: 84px;
        margin-bottom: 0;
        svg {
          height: 38px;
        }
      }
    }
  }
  .onb-type-block {
    .onb-type-box {
      flex-direction: unset;
    }
  }
  .onb-summary-details {
    .cont-text {
      background-color: transparent;
      margin: 0;
      padding: 0;
      .divider {
        display: block;
      }
    }
    .onb-info-blcok {
      margin-top: 0;
    }
  }

  .lb-accordion-block {
    .lb-accordion-header {
      margin-bottom: 1px;
      background-color: transparent;
      border-top: none;
      border-bottom: none;
      height: 42px;
      margin: 0 -20px 10px;

      .icn-arrow {
        display: none;
      }
    }
  }
}

@media (min-width: 1400px) {
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-none {
    display: none !important;
  }

  .onb-right-body {
    display: block !important;

    .onb-chat-body {
      padding: 15px 18px;
    }
    .onb-chat-block {
      right: 30px;
    }
  }

  .onb-content-block {
    padding: 0 52px;
    margin-top: 37px;

    h1 {
      @include fontSize(28px);
    }
    .onb-icn-block {
      display: block;
    }
  }
  .onb-btn {
    &.btn-red {
      width: auto;
      margin-left: 0.625rem;
    }
    &.btn-next {
      width: 40%;
    }
    &.btn-start {
      width: 41%;
      margin-bottom: 30px;
    }
  }

  .progress-container {
    display: block !important;
  }

  .onb-details-block {
    display: block !important;
  }

  .onb-left-banner {
    display: block !important;
  }

  .onb-contact-container {
    .onb-contact-form {
      width: calc(100% - 288px);
      padding: 70px 140px;
      margin-left: 288px;
      h1 {
        @include fontSize(30px);
      }

      p {
        @include fontSize(14px);
      }
    }
  }
  .onb-form-block {
    max-width: 560px;

    .onb-btn-footer {
      .btn-red {
        width: 60%;
      }
    }
  }

  .onb-col-block {
    flex-direction: row !important;
    .onb-left-col {
      width: 50%;
      margin-right: 100px;
    }
    .onb-right-col {
      width: 50%;
    }
  }

  .onb-col {
    text-align: left !important;
  }

  .onb-resend-code {
    align-items: center;
    flex-direction: unset !important;

    .onb-btn-code {
      order: inherit;
      margin: 0 20px 0 0;
    }
    .onb-form-floating {
      margin-bottom: 0;
      width: auto;

      input {
        max-width: 175px;
      }
    }
  }

  .onb-notification-block {
    h3 {
      @include fontSize(20px);
    }

    p {
      @include fontSize(18px);
    }

    .onb-card-block {
      flex-direction: unset;
      margin-bottom: 30px;

      .icn-circle-white {
        width: 84px;
        height: 84px;
        margin-bottom: 0;
        svg {
          height: 38px;
        }
      }
    }
  }
  .onb-type-block {
    .onb-type-box {
      flex-direction: unset;
    }
  }

  .onb-summary-details {
    .cont-text {
      background-color: transparent;
      margin: 0;
      padding: 0;

      .divider {
        display: block;
      }
    }
    .onb-info-blcok {
      margin-top: 0;
    }
  }

  .lb-accordion-block {
    // display: none;

    .lb-accordion-header {
      margin-bottom: 1px;
      background-color: transparent;
      border-top: none;
      border-bottom: none;
      height: 42px;
      margin: 0 -20px 10px;

      .icn-arrow {
        display: none;
      }
    }
  }
}
