.pointer {
  cursor: pointer;
}

.po-s {
  position: static !important;
}
.po-r {
  position: relative;
}
.po-a {
  position: absolute;
}

.inline-block {
  display: inline-block;
}

.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.underline {
  text-decoration: underline;
}

.fnt-b {
  font-weight: bold;
}

.fnt-n {
  font-weight: normal;
}

.mh- .p-0 {
  padding: 0;
}

//margin
.mt-auto {
  margin-top: auto;
}
.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1px;
}
.mt-2 {
  margin-top: 2px;
}
.mt-3 {
  margin-top: 3px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-42 {
  margin-top: 42px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}
.mt-100 {
  margin-top: 100px;
}

//- margin

.mmt-15 {
  margin-top: -15px;
}
.mmt-18 {
  margin-top: -18px;
}
.mmt-20 {
  margin-top: -20px;
}
.mr-auto {
  margin-right: auto;
}
.mr-3 {
  margin-right: 3px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-55 {
  margin-right: 55px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-100 {
  margin-right: 100px;
}

.mb-3 {
  margin-bottom: 3px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-9 {
  margin-bottom: 9px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-190 {
  margin-bottom: 190px;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-5 {
  margin-left: 5px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-80 {
  margin-left: 80px;
}

.mv-auto {
  margin-left: auto;
  margin-right: auto;
}

.mmr-20 {
  margin-right: -20px;
}

.mh-30 {
  margin-right: 30px;
  margin-left: 30px;
}
// horizontal paddings
.ph-5 {
  padding-right: 5px;
  padding-left: 5px;
}
.ph-10 {
  padding-right: 10px;
  padding-left: 10px;
}
.ph-12 {
  padding-right: 12px;
  padding-left: 12px;
}
.ph-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.ph-17 {
  padding-right: 17px;
  padding-left: 17px;
}
.ph-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.ph-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.ph-30 {
  padding-right: 30px;
  padding-left: 30px;
}
.ph-35 {
  padding-right: 35px;
  padding-left: 35px;
}
.ph-40 {
  padding-right: 40px;
  padding-left: 40px;
}
.ph-45 {
  padding-right: 45px;
  padding-left: 45px;
}
.ph-50 {
  padding-right: 50px;
  padding-left: 50px;
}
.ph-90 {
  padding-right: 90px;
  padding-left: 90px;
}

.pt-0 {
  padding-top: 0;
}
.pt-2 {
  padding-top: 2px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-52 {
  padding-top: 52px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-100 {
  padding-top: 100px;
}

.pr-0 {
  padding-right: 0;
}
.pr-5 {
  padding-right: 5px;
}
.pr-6 {
  padding-right: 6px;
}
.pr-7 {
  padding-right: 7px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-22 {
  padding-right: 22px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-70 {
  padding-right: 70px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-7 {
  padding-bottom: 7px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-88 {
  padding-bottom: 88px;
}

.pl-0 {
  padding-left: 0;
}
.pl-2 {
  padding-left: 2px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-60 {
  padding-left: 60px;
}

// vertical paddings
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pv-17 {
  padding-top: 17px;
  padding-bottom: 17px;
}
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

/* =========== text-overflow ellipsis ========== */
.lb-ellipsis-wrap {
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  align-items: center;

  .lb-ellipsis {
    min-width: 0;
    // width: 100%;
    // flex: 1;
  }

  &.text-right .lb-ellipsis {
    width: 100%;
    flex: 1;
  }

  .icn-warning {
    margin: 0 0 0 9px;
  }
}

/* =========== END of text-overflow ellipsis ========== */

/* =========== Main text font classes ========== */

.fnt-7 {
  @include fontSize(7px);
}

.fnt-8 {
  @include fontSize(8px);
}

.fnt-10 {
  @include fontSize(10px);
}

.fnt-11 {
  // color: $transparent-grey;
  @include fontSize(11px);
}
.fnt-12 {
  // color: $transparent-grey;
  @include fontSize(12px);
}

.fnt-13 {
  @include fontSize(13px);
}
.fnt-14 {
  @include fontSize(14px);
}
.fnt-15 {
  @include fontSize(15px);
}
.fnt-16 {
  @include fontSize(16px);
}
.fnt-17 {
  @include fontSize(17px);
}
.fnt-18 {
  @include fontSize(18px);
}
.fnt-19 {
  @include fontSize(19px);
}
.fnt-20 {
  @include fontSize(20px);
}
.fnt-21 {
  @include fontSize(21px);
}
.fnt-22 {
  @include fontSize(22px);
}

.fnt-24 {
  @include fontSize(24px);
}
.fnt-25 {
  @include fontSize(25px);
}

.fnt-25 {
  @include fontSize(25px);
}
.lh-15 {
  line-height: 1.5rem;
}

.lh-40 {
  line-height: 2.5rem;
}

/* =========== font family classes ========== */

.fnt-low {
  font-family: "Liberty-Nus", Arial, sans-serif;
}

.fnt-low-m {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}

.fnt-low-b {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
}

.fnt-upper {
  font-family: "Liberty-MT", Arial, sans-serif;
}

.fnt-upper-m {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
}

.fnt-upper-b {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
}

/* =========== font family classes end ========== */

/* =========== icon modules ========== */
.icn {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  font-size: 0;
  align-items: center;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.icn-circle-wh {
  background-color: $white;
  width: 53px;
  height: 53px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 26px;
    height: 23px;
  }
}

/* =========== flex modules ========== */
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.flex-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-aligned {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-aligned-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.flex-aligned-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.icn-block {
  width: 50px;
  height: 50px;
}

.flex-justify {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-justify-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-justify-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-space,
.lb-block-subhead.flex-space {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.lb-block-subhead.flex-space > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.z-ind1 {
  z-index: 1;
}

//display

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.h-100 {
  height: 100%;
}

//width %

.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}
.w-55 {
  width: 55%;
}
.w-58 {
  width: 58%;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}
.w-97 {
  width: 97.8%;
}
.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto;
}

.is-show {
  display: block;
}
.is-hide {
  display: none;
}
